<template>
  <popup @doSure="doSure" @doClose="doClose" :msg="config.meetTime">
    <div class="meet_time">
      <div class="meet_time_row">
        <div class="meet_time_row_key">
          <span class="global_tag_require"></span
          >{{ data.meet_type == 10 ? "正式会议日期：" : "预定会议日期：" }}
        </div>
        <div class="meet_time_row_value">
          <el-date-picker
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            v-model="meetInfo.date"
            type="datetime"
            :placeholder="`请选择${
              data.meet_type == 10 ? '正式会议时间' : '预定会议时间'
            }`"
            :disabled-date="disabledDate"
            :disabled-hours="disabledHour"
          />
        </div>
      </div>

      <div class="meet_time_row" v-if="false">
        <div class="meet_time_row_key">
          <span class="global_tag_require"></span>会前沟通会时间：
        </div>
        <div class="meet_time_row_value">
          <el-date-picker
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            v-model="meetInfo.testDate"
            type="datetime"
            placeholder="请选择会前沟通会时间"
            :disabled-date="verityTestDate"
          />
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import Popup from "../../unit/Popup.vue";
export default {
  name: "meetTime",
  props: {
    data: {
      type: Object,
      default: {},
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      loading: false,
      config: {
        meetTime: {
          title: "修改会议时间",
          content: "",
          align: "center",
          html: "",
          btngroup: {
            sure: true,
            close: true,
          },
        },
        time: {
          start: 10,
          end: 20,
          day: 86400 * 1000, //24小时
          space: 4,
          audit_status: 0,
          canSelectDay: 0, // 允许几天内
        },
      },
      meetInfo: {
        date: "",
        // testDate: "",
      },
      timer: {
        updateTime: 0, // 更新时间
        space: 100, // 1.5秒请求
        instance: null,
      },
    };
  },

  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.data.meet_start_time) {
        this.meetInfo.date = this.data.meet_start_time;
        // this.meetInfo.testDate = this.data.meet_test_time;
      }
    },

    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    disabledHour() {
      const arrs = [];
      for (let i = 0; i < 10; i++) {
        arrs.push(i);
      }
      for (let i = 21; i <= 24; i++) {
        arrs.push(i);
      }
      return arrs;
    },

    verityTestDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },

    formatData() {
      if (!this.meetInfo.date) {
        this.$tips.error({ text: "请选择正式会议时间" });
        return false;
      }
      // let startDate = this.$tools.getTime(this.$tools.getDate());
      // let hour = Number(this.$tools.getDate(null, "hh"));
      // if (hour >= 20) {
      //   startDate = startDate + 86400 * 1000 + 15 * 60 * 60 * 1000;
      // } else if (20 - hour < 5) {
      //   startDate = startDate + 86400 * 1000 + (5 - 20 + hour) * 60 * 60 * 1000;
      // } else if (hour < 10) {
      //   startDate = startDate + 15 * 60 * 60 * 1000;
      // } else {
      //   startDate = startDate + (hour + 5) * 60 * 60 * 1000;
      // }
      // if (startDate > this.$tools.getTime(this.meetInfo.date)) {
      //   this.$tips.error({ text: "正式会议时间必须在工作时间5小时后" });
      //   return false;
      // }
      if (this.$tools.getTime() > this.$tools.getTime(this.meetInfo.date)) {
        this.$tips.error({ text: "正式会议时间必须在当前工作时间之后" });
        return false;
      }

      // if (!this.meetInfo.testDate) {
      //   this.$tips.error({ text: "请选择会前沟通会时间" });
      //   return false;
      // }

      // if (this.$tools.getTime(this.meetInfo.testDate) < this.$tools.getTime()) {
      //   this.$tips.error({ text: "会前沟通会时间小于当前时间" });
      //   return false;
      // }
      // if (
      //   this.$tools.getTime(this.meetInfo.date) <
      //   this.$tools.getTime(this.meetInfo.testDate) + 1 * 60 * 60 * 1000
      // ) {
      //   this.$tips.error({ text: "会前沟通会时间必须在正式会议一小时前" });
      //   return false;
      // }
      return true;
    },
    doSure() {
      if (!this.formatData() || this.loading) return;
      this.loading = true;
      let Elloading = this.$tips.loading();
      let data = {};
      data.meet_number = this.data.meet_number;
      data.meet_start_time = this.meetInfo.date;
      // data.meet_test_time = this.meetInfo.testDate;
      this.$axios
        .patch(this.$urls.live.meetTime, data)
        .then((res) => {
          this.$tips.success({ text: "修改成功，请前往后台审核课件信息" });
          this.doClose();
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch(() => {
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    doClose() {
      this.$emit("updataShow", false);
      this.$emit("loadMeetingInfo");
    },
  },
};
</script>

<style >
.meet_time {
  margin-top: 1rem;
  font-size: 0.8rem;
}
.meet_time_row {
  display: flex;
  margin: 0.3rem 0;
  align-items: center;
}
.meet_time_row_key {
  width: 7rem;
}
.meet_time_row_value {
  flex: 1 0 0;
}
.meet_time_select_hour :first-child {
  margin-bottom: 0.3rem;
}
.meet_time_row_value .el-date-editor.el-input,
.meet_time_row_value .el-date-editor.el-input__inner {
  width: 100%;
}
.meet_time_row_value .el-select {
  width: 100%;
}
</style>