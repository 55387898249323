<template>
  <div
    class="chat-room-item-container"
    :class="{ 'chat-room-item-mine': isMine() }"
  >
    <!-- <div class="chat-room-item-avater">
      <img :src="require(`@/assets/images/${isMine()?'me':'he'}.jpg`)" alt="">
    </div> -->
    <div class="chat-room-item-content">
      <div
        class="chat-room-item-block"
        :class="{ 'chat-room-item-mine-block': isMine() }"
      >
        <div class="chat-room-item-name">
          <span class="chat-room-role"
            >({{ $config.types.roleMap[data.role_code] || "系统客服" }})</span
          >{{ data.name }}
        </div>
        <div class="chat-room-item-time">
          {{ data.create_time }}
          <!-- {{ $tools.getFormatDate(data.create_time) }} -->
        </div>
      </div>
      <span
        class="chat-room-item-message"
        :class="{
          'chat-room-item-message-he': !isMine(),
          'chat-room-item-message-me': isMine(),
          ...getClass(),
        }"
      >
        <span v-if="data.content_type == 0">{{ data.content }}</span>
        <div v-else-if="allowFileList.test(data.content.split('.').pop())">
          <viewer
            :images="[data.content]"
            :options="{ toolbar: false, navbar: false }"
          >
            <img :src="data.content" @load="loadImage" />
          </viewer>
        </div>
        <div v-else class="chat-room-item-file" @click="downloadFile">
          <div class="chat-room-item-file-name global_two_row">
            {{ getFileName() }}
          </div>
          <div class="chat-room-item-file-icon">
            <div
              class="chat-room-item-file-icon-bg global_container_center"
              :style="getBackgroundStyle()"
            >
              {{ getContentType() }}
            </div>
            <div class="chat-room-item-file-icon-content">
              <div
                class="chat-room-item-file-icon-bg-trangle"
                :style="getPannelStyle()"
              ></div>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import useClipboard from "vue-clipboard3";
export default {
  name: "chat-room-item",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      allowFileList: /png|jpeg|jpg/gi,
      config: {
        images: ["png", "jpeg", "jpg"],
        canDownload: [
          "ppt",
          "pptx",
          "pdf",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "txt",
        ],
        types: {
          red: {
            list: ["ppt", "pptx", "pdf", "rar"],
            color: "#fa5151",
          },
          purple: {
            list: ["video", "mp3"],
            color: "#6467f0",
          },
          blue: {
            list: ["doc", "docx"],
            color: "#2c569a",
          },
          green: {
            list: ["xls", "xlsx"],
            color: "#207346",
          },
          brown: {
            list: ["zip"],
            color: "#8b572a",
          },
        },
      },
      color: "#8b572a",
      toClipboard: null,
    };
  },
  components: {},
  mounted() {
    this.toClipboard = useClipboard().toClipboard;
    this.initColor();
  },
  methods: {
    getBackgroundStyle() {
      return `background: linear-gradient(-135deg, transparent 0.45rem, ${this.color} 0.45rem );`;
    },
    getPannelStyle() {
      return `background: linear-gradient(  -135deg,   transparent 50%,  ${this.color} 50%,  rgba(255, 255, 255, 0.855) 100%);`;
    },
    getFileName() {
      return this.$tools.getFileName(this.data);
    },
    getClass() {
      let result = {
        "chat-room-item-auto": false,
        "chat-room-item-50": false,
      };
      if (
        this.config.images.includes(this.data.type) ||
        this.data.type == "text"
      ) {
        result["chat-room-item-auto"] = true;
      } else {
        result["chat-room-item-50"] = true;
      }
      return result;
    },
    async downloadFile() {
      // window.open(this.data.content)
      // location.href = this.data.content
      let ext = this.data.content.split(".").pop();
      let regText = this.config.canDownload.join("|");
      let regInstance = new RegExp(regText, "i");
      if (this.$tools.isWx() && !regInstance.test(ext)) {
        await this.toClipboard(this.data.content);
        this.$tips.info({
          text: "由于微信限制，当前文件格式不支持下载，文件链接已经复制，可以在浏览器打开下载",
        });
      } else {
        var a = document.createElement("a");
        a.target = "_blank";
        a.href = this.data.content;
        a.download = this.getFileName();
        a.click();
      }
    },
    loadImage() {
      this.$emit("initScroll");
    },
    getContentType() {
      return this.data.content.split(".").pop();
    },
    initColor() {
      let color = this.color;
      let type = this.data.content.split(".").pop();
      for (let index in this.config.types) {
        let item = this.config.types[index];
        let regText = item.list.join("|");
        let regInstance = new RegExp(regText, "i");
        if (regInstance.test(type)) {
          console.log(item.color);
          color = item.color;
          break;
        }
      }
      this.color = color;
    },
    isMine() {
      // if(this.data.type )
      let role =
        this.$route.query.role === undefined
          ? 1
          : Number(this.$route.query.role);
      let id =
        role == 0
          ? this.$store.state.common.executorInfo.id
          : this.$store.state.common.userInfo.id;
      return this.data.user_id == id && this.data.role == role;
    },
  },
};
</script>
<style>
/* .chat-room-item-container{
  color:#fa5151;
  color:#6467f0;
  color:#2c569a;
  color:#207346;
  color:#8b572a;
} */
.chat-room-item-container {
  width: 100%;
  display: flex;
  min-height: 4rem;
}
.chat-room-role {
  color: #e64638;
  font-size: 0.8rem;
  margin-right: 0.2rem;
}
.chat-room-item-mine {
  flex-direction: row-reverse;
}
.chat-room-item-avater {
  padding: 0.5rem;
  flex: 4rem 0 0;
}
.chat-room-item-avater img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}
.chat-room-item-content {
  /* flex:1 0 0; */
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* max-width:50vw; */
  width: auto;
}
.chat-room-item-block {
  display: flex;
  align-items: center;
}
.chat-room-item-mine-block {
  justify-content: flex-end;
}
/* .chat-room-item-message-he::before{
  content:"";
  height:0;
  width:0;
  border-width:0.5rem;
  border-color:transparent;
  border-right-color: #fff;
  border-style: solid;
  position: absolute;
  left:-3.5vw;
  top:0.6rem;
}
.chat-room-item-message-me:after{
  content:"";
  height:0;
  width:0;
  border-width:0.5rem;
  border-color:transparent;
  border-left-color: #fff; 
  border-style: solid;
  position: absolute;
  right:-3.5vw;
  top:0.6rem;

} */
.chat-room-item-message {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #000;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.2rem;
  position: relative;
  line-height: 1.2rem;
  word-break: break-all;
  width: auto;
  white-space: pre-line;
}
.chat-room-item-name {
  font-size: 0.9rem;
}
.chat-room-item-mine-time {
  margin-right: 0.5rem;
}
.chat-room-item-time {
  color: #999;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.chat-room-item-message img {
  width: 100%;
  height: auto;
}
.chat-room-item-file {
  display: flex;
  padding: 0.5rem;
  line-height: 1.2rem;
  font-size: 0.8rem;
}
.chat-room-item-file-name {
  flex: 1 0 0;
}
.chat-room-item-file-icon {
  height: 2.4rem;
  width: 1.9rem;
  /* background:red; */
  position: relative;
}
.chat-room-item-file-icon-bg {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.065rem;
  font-size: 0.6rem;
  color: #fff;
  /* color:#fff; */
  /* filter: drop-shadow( 0px 1px 10px rgba(124, 124, 124, 0.4)); */
}
.chat-room-item-auto {
  width: auto;
  max-width: 12.5rem;
}
.chat-room-item-50 {
  width: 12.5rem;
}
.chat-room-item-file-icon-content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.chat-room-item-file-icon-bg-trangle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  overflow: hidden;
  width: 0.6rem;
  height: 0.6rem;
  filter: drop-shadow(-2px -2px 8px rgba(0, 0, 0, 0.08));
}
</style>
