
<template>
  <div class="chat-room-container">
    <div class="tips">{{ meetingInfo.meet_number }}会议聊天室</div>
    <div class="group_btn">
      <div class="chat-material">
        <div
          class="chat-room-send"
          @click="downLoadFile('file_regular_pic_invitation')"
          :class="{
            'chat-room-no-file':
              !meetingInfo.profile?.file_regular_pic_invitation,
          }"
        >
          会议海报
        </div>
        <div
          class="chat-room-send"
          @click="downLoadFile('file_schedule')"
          :class="{
            'chat-room-no-file': !meetingInfo.profile?.file_schedule,
          }"
        >
          会议日程
        </div>
        <div
          class="chat-room-send"
          :class="{
            'chat-room-no-file': !meetingInfo.profile?.file_slide_show,
          }"
          @click="downLoadFile('file_slide_show')"
        >
          串场幻灯
        </div>
      </div>
      <div class="chat-material">
        <!-- <div class="chat-room-send" @click="downLoadFile('xxx')">
          测试会议海报
        </div> -->
        <!-- <div class="chat-room-send" @click="downLoadFile('control_book')">
          操作手册
        </div> -->
        <div class="chat-room-send" @click="downLoadFile('wx_qrcode')">
          找运营
        </div>
        <div class="chat-room-send" @click="lookMedicalAssistance">
          医助信息
        </div>
        <div
          class="chat-room-send"
          @click="watchSignList"
          v-if="$tools.isExecutor()"
        >
          会议签到详情
        </div>
        <div
          class="chat-room-send"
          @click="uploadMeetTime"
          v-if="$tools.isExecutor() && false && getTestTime()"
        >
          修改会议时间
        </div>
      </div>
      <div class="chat-material" v-if="$tools.isExecutor()">
        <div class="chat-room-send" @click="goToPrompt('people')">
          会议人数不足提醒
        </div>
        <div class="chat-room-send" @click="goToPrompt('time')">
          专家时长不足提醒
        </div>
        <div class="chat-room-send" @click="goToPrompt('templateInfo')">
          通用模板消息
        </div>
      </div>
    </div>
    <div class="tips_group">
      <div>1、会议召开前48小时内，方可下载物料</div>
      <div>
        2、海报、日程、串场幻灯如有修改可直接在本聊天室内反馈，运营人员会配合处理
      </div>
      <div>
        3、本聊天框中的重要信息，均会以公众号消息推送到参会专家及运营人员。
      </div>
      <div>
        若您需单独联系运营人员，请点击找运营，工作时间内（工作日9:30-18:30）运营将在1小时内联系您
      </div>
    </div>
    <div class="chat-room-main" ref="chatList">
      <ItemScroll ref="ItemScroll" :isBottom="true">
        <div>
          <chat-room-item
            @initScroll="initScroll"
            :data="item"
            v-for="(item, index) in dataList"
            :key="index"
          ></chat-room-item>
        </div>
      </ItemScroll>
    </div>
    <div class="chat-room-block">
      <div class="chat-room-input global_text_input">
        <el-input placeholder="请输入" v-model.trim="message"> </el-input>

        <!-- <el-input
          type="textarea"
          placeholder="请输入"
          :autosize="{ minRows: 1, maxRows: 1 }"
          v-model="message"
        >
        </el-input> -->
      </div>
      <div class="chat-room-group">
        <i
          class="el-icon-circle-plus-outline"
          @click="clickFile()"
          v-if="!message"
        ></i>
        <div class="chat-room-button" @click="sendChat" v-else>发送</div>
      </div>
    </div>
    <input
      type="file"
      v-show="false"
      ref="resetFile"
      v-if="resetFile"
      @change="changeFile"
    />
    <Popup
      @doSure="doSure"
      @doClose="config.isShow = false"
      v-if="config.isShow"
      :msg="config.msg.meetPrompt"
    >
      <div class="chat-room-row">
        <div class="chat-room-row-key">
          {{ type == "people" ? "目前人数" : "目前时长" }}
        </div>
        <div class="chat-room-row-key-value">
          <el-input
            v-if="type !== 'people'"
            v-model="this.meetPrompt.status_minute"
            @input="chagneNumber"
            placeholder="请输入"
          >
            <template #append>分钟</template>
          </el-input>
          <el-input
            v-else
            @input="chagneNumber"
            v-model="this.meetPrompt.status_minute"
            placeholder="请输入"
          />
        </div>
      </div>
      <div class="chat-room-row" v-if="type != 'people'">
        <div class="chat-room-row-key">提醒接收人</div>
        <div class="chat-room-row-key-value">
          <el-select
            v-model="meetPrompt.name"
            class="m-2"
            placeholder="请选择提醒接收人"
            size="large"
          >
            <el-option
              v-for="item in doctorsList"
              :key="item.value"
              :label="`${item.role_name}-${item.name}`"
              :value="item.name"
            />
          </el-select>
        </div>
      </div>
    </Popup>

    <Popup @doSure="showSign = false" v-if="showSign" :msg="config.msg.sign">
      <div class="chat-room-sign-ul">
        <div class="chat-room-sign-li">
          <div class="chat-room-sign-row">
            签到人数总计【{{ config.signList.length }}】人
          </div>
        </div>
        <div class="chat-room-sign-li">
          <div class="chat-room-sign-row">序号</div>
          <div class="chat-room-sign-row">姓名</div>
          <div class="chat-room-sign-row">医院</div>
        </div>
        <div
          class="chat-room-sign-li"
          v-for="(item, index) in config.signList"
          :key="item"
        >
          <div class="chat-room-sign-row">{{ index + 1 }}</div>
          <div class="chat-room-sign-row">{{ item.name }}</div>
          <div class="chat-room-sign-row">{{ item.hospital }}</div>
        </div>
      </div>
    </Popup>

    <Popup
      @doSure="doSure"
      @doClose="config.showTemplateInfo = false"
      v-if="config.showTemplateInfo"
      :msg="config.msg.templateInfo"
    >
      <div class="chat-room-row">
        <div class="chat-room-row-key">提醒接收人</div>
        <div class="chat-room-row-key-value">
          <el-select
            v-model="templateInfo.user_ids"
            class="m-2"
            placeholder="请选择提醒接收人"
            size="large"
            multiple
          >
            <el-option
              v-for="item in doctorsList"
              :key="item.value"
              :label="`${item.role_name}-${item.name}`"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
      <div class="chat-room-row">
        <div class="chat-room-row-key">发送内容</div>
        <div class="chat-room-row-key-value">
          <el-input
            v-model="this.templateInfo.text"
            @input="chagneNumber"
            placeholder="请输入"
            type="textarea"
            show-word-limit
            maxlength="20"
            :rows="2"
          >
          </el-input>
        </div>
      </div>
    </Popup>
    <Popup
      class="medical-pop"
      @doSure="config.medical = false"
      @doClose="config.medical = false"
      v-if="config.medical"
      :msg="config.msg.medical"
    >
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="area" label="大区" />
        <el-table-column prop="sales_name" label="医助姓名" />
        <el-table-column prop="sales_phone" label="医助电话" width="110" />
        <el-table-column prop="name" label="专家姓名" />
      </el-table>
    </Popup>

    <meetTime
      :data="meetingInfo"
      v-if="config.meetTime"
      :isShow="config.meetTime"
      @updataShow="updataShow"
      @loadMeetingInfo="loadMeetingInfo"
    ></meetTime>
  </div>
</template>
<script>
import websockets from "@/common/websockets";
import uploads from "@/common/uploads";
import ItemScroll from "@/components/unit/ItemScroll";
import chatRoomItem from "@/components/page/chat/chatRoomItem";
import { ElInput } from "element-plus";
import useClipboard from "vue-clipboard3";
import PopopInstance from "@/components/unit/PopupInstance";
import Popup from "@/components/unit/Popup.vue";
import meetTime from "./meetTime.vue";

export default {
  name: "chatRoom",
  data() {
    return {
      tableData: [],
      showPopup: true,
      config: {
        signList: [
          { name: "李四", hospital: "四川华西医院" },
          { name: "李四的", hospital: "四川华西医院四川华西医院四川华西医院" },
        ],
        tip: {
          title: "提示",
          button: ["确认"],
        },
        isShow: false,
        showTemplateInfo: false,
        medical: false,
        meetTime: false,
        msg: {
          sign: {
            title: "会议签到详情",
            align: "center",
            btngroup: {
              sure: true,
            },
          },
          welcome: {
            title: "运营人员",
            align: "center",
            html: "",
            btngroup: {
              sure: true,
            },
          },
          meetPrompt: {
            title: "会议提醒",
            content: "目前人数：",
            align: "center",
            html: "",
            btngroup: {
              sure: true,
              close: true,
            },
          },
          templateInfo: {
            isShow: false,
            title: "会议提醒",
            content: "通用模板消息发送：",
            align: "center",
            html: "",
            btngroup: {
              sure: true,
              close: true,
            },
          },
          medical: {
            isShow: false,
            title: "医助信息",
            align: "center",
            btngroup: {
              sure: true,
            },
          },
        },
      },
      showSign: false,

      data: {},
      dataList: [],
      doctorsList: [],
      message: "",
      resetFile: true,
      events: {
        login: "dealWithLogin",
        chat: "dealWithChat",
        error: "dealWithError",
      },
      role: 1,
      meetingInfo: {},
      toClipboard: null,
      meetPrompt: {
        name: "",
        phase_minute: "",
        status_minute: "",
      },
      templateInfo: {
        id: "",
        user_ids: [],
        text: "",
      },
      customerInfo: {},
      loading: false,
      speakTime: {
        讲者: 20,
        主席: 10,
        讨论: 10,
      },
    };
  },
  components: {
    ItemScroll,
    ElInput,
    chatRoomItem,
    Popup,
    meetTime,
  },
  beforeUnmount() {
    websockets.close();
  },
  mounted() {
    this.toClipboard = useClipboard().toClipboard;
    this.role =
      this.$route.query.role === undefined ? 1 : Number(this.$route.query.role);
    this.loadData();
    this.getCustomerInfo();
    this.loadDoctorsData();
    this.loadMeetingInfo();
    this.initWebScoket();
    this.initEnter();
    this.$nextTick(() => {
      this.initScroll();
    });
  },
  methods: {
    getCustomerInfo() {
      let url = this.$tools.getURL(this.$urls.live.customerInfo);
      this.$axios.get(url).then((res) => {
        res.data;
        this.customerInfo = res.data;
      });
    },
    getTestTime() {
      return (
        this.$tools.getTime(this.meetingInfo.meet_test_time) >
        this.$tools.getTime()
      );
    },
    showPhonePopup() {
      //       this.config.msg.welcome.html = `
      //             <div class="wx_qrcode_block">
      //               <img class="wx_qrcode_img" src="${this.customerInfo.link}" alt="" />
      // <div>请扫描上方二维码添加客服</div><div>若您有紧急事项需要联系，请拨打客服电话：19113959856</div><div>客服在线时间为工作日9:30-18:30\n非工作日期间如有问题，请您留言，我们将在上线后第一时间与您联系，感谢您的理解与支持</div>
      //               </div>`;
      this.config.msg.welcome.html = `
            <div class="wx_qrcode_block">
              <img class="wx_qrcode_img" src="${this.customerInfo?.link}" alt="" />
              <div>运营人员工作日在线时间为9:30-18:30\n非工作时间如有问题请您留言，我们将在上线后第一时间联系您，感谢您的理解与支持</div></div>`;
      PopopInstance(this.config.msg.welcome)
        .then(() => {
          this.$nextTick(() => {
            // this.meetingInfo.isCanPlay = true;
            // this.$refs.player.play();
          });
        })
        .catch(() => {
          console.log("关闭");
        });
    },
    loadMeetingInfo() {
      let url = this.$tools.getURL(
        this.$urls.live.detailAll,
        this.$route.query
      );
      this.$axios.get(url).then((res) => {
        this.meetingInfo = res.data;
        this.tableData = res.data.sale_data;
      });
    },
    initWebScoket() {
      let data = {
        role: this.role,
        user_id:
          this.role === 0
            ? this.$store.state.common.executorInfo.id
            : this.$store.state.common.userInfo.id,
      };
      websockets.initWebsocket(
        this.$route.query.id,
        this.$config.chat,
        this.receive,
        data,
        true
      );
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.live.chatList, this.$route.query);
      this.$axios.get(url).then((res) => {
        this.dataList = res.data;
        this.initScroll();
      });
    },
    loadDoctorsData() {
      let url = this.$tools.getURL(this.$urls.user.users, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        this.doctorsList = res.data.map((v) => {
          return {
            role_name: v.role_name,
            openid: v.openid,
            name: v.name,
            id: v.id,
          };
        });
        this.initScroll();
      });
    },
    chagneNumber() {
      this.meetPrompt.status_minute = this.meetPrompt.status_minute.replace(
        /\D/g,
        ""
      );
    },
    initPrompt() {
      this.meetPrompt = {
        name: "",
        phase_minute: "",
        status_minute: "",
      };
    },
    async downLoadFile(key) {
      if (key == "wx_qrcode") {
        this.showPhonePopup();
        return;
      }

      let fileName =
        this.meetingInfo[key] || this.meetingInfo.profile?.[key] || "";
      if (!fileName) {
        this.$tips.error({ text: "物料制作中，请稍后" });
        return;
      }
      let ext = fileName.split(".").pop();
      if (/png|jpeg|jpg/i.test(ext)) {
        this.$viewerApi({
          images: [fileName],
          options: { toolbar: false, navbar: false },
        });
        return;
      } else {
        // 复制
        window.open(fileName);
        // await this.toClipboard(fileName);
        // this.$tips.warning({
        //   text: "由于微信限制，当前文件格式不支持下载，文件链接已经复制，可以在浏览器打开下载",
        // });
      }
    },
    receive(res) {
      let key = this.events[res.type];
      if (!key) return;
      console.log("receive", res);
      this[key](res.data);
    },
    doSelect() {
      this.showPopup = false;
    },
    dealWithLogin(data) {
      // data.chat_list = data.chat_list.slice(-20);
      this.initScroll();
    },
    dealWithChat(data) {
      this.dataList.push(data);
      this.initScroll();
    },
    dealWithError(data) {
      console.log(data);
    },
    initEnter() {
      document.onkeydown = () => {
        var key = window.event.keyCode;
        if (key == 13) {
          this.sendChat();
        }
      };
    },
    async changeFile(e) {
      let file = e.target.files[0];
      this.resetFile = false;
      this.$nextTick(() => {
        this.resetFile = true;
      });
      if (!file) return;
      let size = file.size / 1024 / 1024;
      if (size > 100) {
        this.$tips.error({ text: "文件不允许超过100M" });
        return;
      }
      let res = await uploads.uploadFile(
        file,
        "png|jpeg|jpg|pdf|ppt|pptx|doc|docx|xls|xlsx",
        "files",
        "",
        {
          type: `chat/${this.$route.query.id}`,
        }
      );
      if (res?.data?.[0]) {
        this.sendFile(res.data[0]);
      }
    },
    clickFile() {
      this.$refs.resetFile.click();
    },
    sendFile(fileURL) {
      this.sendMessage({
        type: "chat",
        data: this.getContent(fileURL, 1),
      });
    },
    sendChat() {
      if (!this.message) return;
      this.sendMessage({
        type: "chat",
        data: this.getContent(this.message),
      });
      this.message = "";
    },
    getUserInfo() {
      // role 0是管理员 1 是专家
      if (this.role === 0) {
        return {
          user_id: this.$store.state.common.executorInfo.id,
          name: this.$store.state.common.executorInfo.name,
          hospital: "",
          role: this.role,
        };
      } else {
        return {
          user_id: this.$store.state.common.userInfo.id,
          name: this.$store.state.common.userInfo.name,
          hospital: this.$store.state.common.userInfo.hospital,
          role: this.role,
        };
      }
    },
    getContent(content, type = 0) {
      return {
        ...this.getUserInfo(),
        content: content,
        post_id: this.$route.query.id,
        content_type: type,
      };
    },
    sendMessage(data) {
      websockets.sendMessage(data);
    },
    uploadFile(type) {
      if (type == "material") {
        this.$tips.error({ text: "敬请期待-----会议物料" });
      } else {
        this.$tips.error({ text: "敬请期待-----邀请函" });
      }
    },
    initScroll(status = false) {
      this.$nextTick(() => {
        console.log(this.$refs.ItemScroll);
        this.$refs.ItemScroll.initScroll(status);
      });
    },

    watchSignList() {
      if (this.loading) return;
      this.loading = true;
      let Elloading = this.$tips.loading();
      let url = this.$tools.getURL(this.$urls.live.signUser, {
        ...this.$route.query,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.signList = res.data;
          this.showSign = true;
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToPrompt(type) {
      this.type = type;
      type == "templateInfo"
        ? (this.config.showTemplateInfo = true)
        : (this.config.isShow = true);
    },
    doSure() {
      if (this.loading) return;
      this.type == "people"
        ? this.goToPeoplePrompt()
        : this.type == "time"
        ? this.goToTimePrompt()
        : this.goToInfoPrompt();
    },
    goToPeoplePrompt() {
      if (!this.meetPrompt.status_minute) {
        return this.$tips.error({ text: "请输入目前人数" });
      }
      if (this.meetPrompt.status_minute >= this.getGuestCount()) {
        return this.$tips.error({ text: "人数已足够" });
      }
      this.loading = true;
      let Elloading = this.$tips.loading();

      this.meetPrompt = { ...this.meetPrompt, id: this.$route.query.id };
      let url = this.$tools.getURL(
        this.$urls.live.sendMeetUserChat,
        this.meetPrompt
      );
      this.$axios
        .get(url)
        .then((res) => {
          this.config.isShow = false;
          this.sendPeopleTip();
          this.initPrompt();
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.initScroll();
        })
        .catch((err) => {
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToTimePrompt() {
      if (!this.meetPrompt.status_minute) {
        return this.$tips.error({ text: "请输入目前时长" });
      }
      if (!this.meetPrompt.name) {
        return this.$tips.error({ text: "请选择提醒接收人" });
      }
      let result = this.doctorsList.filter(
        (v) => v.name == this.meetPrompt.name
      )[0];
      this.meetPrompt.phase_minute = this.speakTime[result.role_name];
      if (this.meetPrompt.status_minute >= this.meetPrompt.phase_minute) {
        return this.$tips.error({ text: "该专家时长已足够" });
      }
      this.meetPrompt.openid = result.openid;
      this.loading = true;
      let Elloading = this.$tips.loading();
      this.meetPrompt.title = this.meetingInfo.title;

      this.meetPrompt = {
        ...this.meetPrompt,
        ...result,
        id: this.$route.query.id,
      };
      let url = this.$tools.getURL(
        this.$urls.live.sendMeetTimeChat,
        this.meetPrompt
      );
      this.$axios
        .get(url)
        .then((res) => {
          console.log("res :>> ", res);
          this.config.isShow = false;
          this.sendTimeTip();
          this.initPrompt();
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.initScroll();
        })
        .catch((err) => {
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    getGuestCount() {
      let count = 0;
      for (let key in this.$config.types.roles) {
        count += this.meetingInfo[this.$config.types.roles[key]].length;
      }
      return count * 3 + 1;
    },
    sendTimeTip() {
      let message = `发言时长不足${this.meetPrompt.phase_minute}分钟提醒：（${this.meetPrompt.name}）当前时长${this.meetPrompt.status_minute}分钟，请继续发言`;
      this.sendMessage({
        type: "chat",
        data: this.getContent(message),
      });
    },
    sendPeopleTip() {
      let message = `观众不足${this.getGuestCount()}人提醒：当前观众${
        this.meetPrompt.status_minute
      }人，请尽快邀请观众`;
      this.sendMessage({
        type: "chat",
        data: this.getContent(message),
      });
    },
    goToInfoPrompt() {
      if (!this.templateInfo.user_ids) {
        return this.$tips.error({ text: "请选择提醒接收人" });
      }
      if (!this.templateInfo.text) {
        return this.$tips.error({ text: "请输入发送内容" });
      }
      let name = this.doctorsList
        .filter((item) => this.templateInfo.user_ids.includes(item.id))
        .map((doctor) => doctor.name);

      this.templateInfo.user_ids = this.templateInfo.user_ids.join(",");
      this.loading = true;

      let Elloading = this.$tips.loading();
      this.templateInfo = {
        ...this.templateInfo,
        id: this.$route.query.id,
      };
      let url = this.$tools.getURL(
        this.$urls.live.sendCommon,
        this.templateInfo
      );
      let message = `会议提醒：（${name.join(",")}）${this.templateInfo.text}`;
      this.$axios
        .get(url)
        .then((res) => {
          this.config.showTemplateInfo = false;
          this.sendMessage({
            type: "chat",
            data: this.getContent(message),
          });
          this.templateInfo = {
            id: "",
            user_ids: [],
            text: "",
          };
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.initScroll();
        })
        .catch((err) => {
          Elloading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    //修改会议时间
    uploadMeetTime() {
      this.config.meetTime = true;
    },
    updataShow(status) {
      this.config.meetTime = status;
    },
    //查看医助信息
    lookMedicalAssistance() {
      this.config.medical = true;
      // let msg = {
      //   title: "温馨提示",
      //   align: "center",
      //   html: `
      //       <div class="wx_qrcode_block">

      //         <div>运营人员工作日在线时间为9:30-18:30\n非工作时间如有问题请您留言，我们将在上线后第一时间联系您，感谢您的理解与支持</div></div>`,
      //   btngroup: {
      //     sure: true,
      //   },
      // };
      // PopopInstance(msg)
      //   .then(() => {})
      //   .catch(() => {});
    },
  },
};
</script>
<style>
.chat-room-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}
.chat-room-main {
  flex: 1 0 0;
  overflow: hidden;
}
.chat-room-block {
  width: 100%;
  display: flex;
  background: #f7f7f7;
  border-top: 1px solid #ccc;
  padding: 0.25rem;
  align-items: center;
}
.chat-room-input {
  flex: 1 0 0;
}
.chat-room-group {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  height: 2.2rem;
}
.chat-room-send {
  background: #e64638;
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem !important;
}
.chat-room-no-file {
  background: #ccc !important;
}
.chat-room-button {
  background: #e64638;
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}
.chat-room-group i {
  font-size: 1.5rem;
}
.chat-room-input .el-textarea__inner {
  padding: 0.25rem 0.5rem;
  min-height: 2.2rem;
  line-height: 1.7rem;
  border: none;
  font-size: 1rem;
}
.chat-room-input .el-input_inner {
  padding: 0.25rem 0.5rem;
  min-height: 2.2rem;
  line-height: 6.8vw;
  border: none;
  font-size: 1rem;
}
.chat-room-container .tips {
  width: 100%;
  background: #e64638;
  line-height: 1rem;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  white-space: pre-line;
  color: #fff;
}
.group_btn {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 1rem;
}
.chat-material {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.chat-room-send {
  flex: 1 0 0;
  margin-right: 0.5rem;
}
.chat-room-send:last-child {
  margin-right: 0;
}
.wx_qrcode_block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.wx_qrcode_block div {
  font-size: 0.8rem;
}
.wx_qrcode_img {
  width: 80%;
  object-fit: contain;
  height: auto;
}
.chat-room-row {
  height: 2rem;
  width: 100%;
  display: flex;
  margin: 1rem 0;
  align-items: center;
}
.chat-room-row-key {
  flex: 4.8rem 0 0;
  font-size: 0.8rem;
}

.chat-room-row-key-value input:: {
  font-size: 0.8rem;
}
.chat-room-row-key-value .el-input-group__append {
  font-size: 0.8rem;
  padding: 0 1rem;
}
.chat-room-sign-ul {
  max-height: 50vh;
  overflow: auto;
  font-size: 0.8rem;
  width: 100%;
}
.chat-room-sign-li {
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 1px solid #ccc;
}
.chat-room-sign-li:nth-child(2) {
  font-weight: 600;
}
.chat-room-sign-row {
  text-align: left;
  display: flex;
  align-items: center;
}
.chat-room-sign-row:nth-child(1) {
  flex: 2rem 0 0;
}
.chat-room-sign-row:nth-child(2) {
  flex: 4rem 0 0;
}
.chat-room-sign-row:nth-child(3) {
  flex: 1 0 0;
}
.chat-room-sign-li:first-child .chat-room-sign-row {
  flex: 1 0 0;
  justify-content: center;
  font-weight: 600;
  color: #e64638;
}

.chat-room-send {
  font-size: 0.7rem;
}
.chat-room-container .tips_group {
  margin: 0 1rem 1rem 1rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #ccc;
}
.chat-room-container .tips_group div {
  font-size: 0.8rem;
  line-height: 1.5;
}
.chat-room-input .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.medical-pop .popup_main {
  width: 95%;
}

.chat-room-input .el-input__wrapper,
.chat-room-input .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.chat-room-input .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.chat-room-row-key-value .el-input__wrapper.is-focus,
.chat-room-row-key-value .el-select .el-input.is-focus .el-input__wrapper {
  -webkit-box-shadow: 0 0 0 1px
    var(--el-input-border-color, var(--el-border-color)) inset !important;
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset !important;
}
</style>